import Image from 'next/legacy/image'

import Box from '@totallymoney/ui/components/Box'

interface ScoredTableItemImageProps {
    cmsImageUrl: string
    isPortraitImage: boolean
    name: string
}

const ScoredTableItemImage = ({
    name,
    cmsImageUrl,
    isPortraitImage,
}: ScoredTableItemImageProps) => {
    const imageDimensions = !isPortraitImage
        ? { width: 120, height: 76 }
        : { width: 47, height: 74 }

    return (
        <Box
            display="flex"
            width="100%"
            justifyContent={['center', 'flex-start']}
            maxWidth={['unset', '220px']}
        >
            <Image
                src={cmsImageUrl}
                alt={`${name} credit card`}
                {...imageDimensions}
            />
        </Box>
    )
}

export default ScoredTableItemImage
