import Skeleton from 'react-loading-skeleton'

import Box from '@totallymoney/ui/components/Box'
import Grid from '@totallymoney/ui/components/Grid'
import theme from '@totallymoney/ui/theme'

import * as Layout from '../ScoredTable/components/ScoredTableItem/components/ScoredTableItemLayoutLegacy'
import {
    SkeletonBack,
    SkeletonButtons,
    SkeletonCategoryNavigation,
    SkeletonContent,
    SkeletonContentItem,
    SkeletonDescription,
    SkeletonHeading,
    SkeletonSorter,
    SkeletonTitle,
} from './ScoredTableSkeleton.styled'

const SkeletonScoredTableItem = () => (
    <Layout.ItemBase productCode="skeleton">
        <Layout.ItemWrapper>
            <Layout.ItemHeading>
                <SkeletonHeading />
            </Layout.ItemHeading>

            <Layout.ItemImage>
                <Skeleton
                    height={132}
                    width={180}
                    borderRadius={theme.radii.borderRadius10}
                />
            </Layout.ItemImage>

            <Layout.ItemIndicatorContent>
                <Skeleton
                    height={80}
                    width={208}
                    borderRadius={theme.radii.borderRadius10}
                />
            </Layout.ItemIndicatorContent>

            <Layout.ItemContent>
                <SkeletonContent>
                    <SkeletonContentItem count={4} inline={true} />
                </SkeletonContent>
            </Layout.ItemContent>

            <Layout.ItemButtons>
                <SkeletonButtons count={2} />
            </Layout.ItemButtons>
        </Layout.ItemWrapper>

        <Layout.ItemFooter>
            <Skeleton count={2} borderRadius={theme.radii.borderRadius10} />
        </Layout.ItemFooter>
    </Layout.ItemBase>
)

const ScoredTableSkeleton = () => (
    <Box backgroundColor="productNeutral60" py="space20">
        <Grid>
            <Box pb="space30">
                <Box pb="10px">
                    <SkeletonBack />
                </Box>

                <Box pb="space10">
                    <SkeletonTitle />
                </Box>

                <SkeletonDescription />
            </Box>
        </Grid>

        <Box mb="space50">
            <Grid>
                <Box
                    gridColumn="1/13"
                    overflowY="hidden"
                    overflowX="scroll"
                    height={38}
                    width="100%"
                    mb="space30"
                >
                    <SkeletonCategoryNavigation
                        count={6}
                        inline={true}
                        height={38}
                        width={120}
                    />
                </Box>

                <Box gridColumn="1/13" flex={1} style={{ textAlign: 'right' }}>
                    <SkeletonSorter />
                </Box>
            </Grid>
        </Box>

        <Grid>
            <Box display="grid" gridColumn="1/13" gridGap="space30">
                <SkeletonScoredTableItem />
                <SkeletonScoredTableItem />
                <SkeletonScoredTableItem />
            </Box>
        </Grid>
    </Box>
)

export default ScoredTableSkeleton
