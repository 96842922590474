import { useQuery } from '@tanstack/react-query'

import { FetchCreditCardAlternativeProductsResponse } from '@appTypes/EligibilityApi.interface'

import { useBasin } from '@hooks/useBasin'

import getAuthHeaders from './utils/getAuthHeaders'
import { handleApiResponse } from './utils/handleApiResponse'

interface Options {
    body: {
        customerId: string
        scoreId: string
    }
    shouldFetchAlternativeProducts: boolean
}

export const useAlternativeProductCategoriesQuery = ({
    body,
    shouldFetchAlternativeProducts,
}: Options) => {
    const { ids } = useBasin()

    return useQuery<FetchCreditCardAlternativeProductsResponse>(
        ['alternativeProductCategories', body.scoreId, body.customerId],
        () =>
            fetch(
                `${process.env.NEXT_PUBLIC_BASE_PATH}/api/alternative-products/`,
                {
                    body: JSON.stringify(body),
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        ...getAuthHeaders(ids),
                    },
                }
            ).then(handleApiResponse),
        {
            staleTime: Infinity,
            refetchOnWindowFocus: false,
            retry: 3,
            enabled:
                !!body.scoreId &&
                !!body.customerId &&
                shouldFetchAlternativeProducts,
        }
    )
}
