import Box from '@totallymoney/ui/components/Box'
import CategoryNavigation from '@totallymoney/ui/components/CategoryNavigation'
import Grid from '@totallymoney/ui/components/Grid'

import {
    CreditCardProductCategory,
    ScoredCreditCardProductsCategory,
} from '@appTypes/EligibilityApi.interface'

import KbaStatusBanner from '@components/KbaStatusBanner'
import TableSorter from '@components/TableSorter'

import { hasKbaScenario } from '@config/kbaScenarioKeys'

import useAppQueryState from '@hooks/useAppQueryState'

import { getUnscoredCategoryPath } from '@utils/getUnscoredCategoryPath'

interface TableHeaderProps {
    activeCategory: CreditCardProductCategory
    categories: ScoredCreditCardProductsCategory[]
    onCategoryChange: (category: CreditCardProductCategory) => void
    onSortChange: (sortKey: string) => void
    sortOptions: Record<string, string>
    sortValue: string
}

const UnscoredTableHeader = ({
    activeCategory,
    categories,
    sortValue,
    sortOptions,
    onSortChange,
    onCategoryChange,
}: TableHeaderProps) => {
    const { appQueryState, goToRouteWithAppState, getRouteUrl } =
        useAppQueryState()

    return (
        <>
            <CategoryNavigation
                categories={categories.map(category => ({
                    displayName: category.displayName,
                    value: category.id,
                    url: getRouteUrl(getUnscoredCategoryPath(category.id)),
                }))}
                initialActiveCategory={activeCategory}
                onCategoryChange={category => {
                    const categoryValue =
                        category.value as CreditCardProductCategory

                    goToRouteWithAppState(
                        getUnscoredCategoryPath(categoryValue),
                        {
                            shallow: true,
                        }
                    )

                    onCategoryChange?.(categoryValue)
                }}
            />
            {hasKbaScenario(appQueryState.kbaScenario) ? (
                <KbaStatusBanner kbaScenario={appQueryState.kbaScenario} />
            ) : null}
            <Grid>
                <Box gridColumn={['1/13', '9/13']} mt={['space20', 'space30']}>
                    <TableSorter
                        value={sortValue}
                        sortOptions={sortOptions}
                        onSortChange={sortKey => {
                            onSortChange?.(sortKey)
                        }}
                    />
                </Box>
            </Grid>
        </>
    )
}

export default UnscoredTableHeader
