import Image from 'next/image'

import Box from '@totallymoney/ui/components/Box'
import Card from '@totallymoney/ui/components/Card'
import Heading from '@totallymoney/ui/components/Heading'
import Text from '@totallymoney/ui/components/Text'

import personReceivingOffers from '../../../../../../public/images/person-receiving-offers.png'

const ScoredTableNewOfferCard = ({
    numberOfNewOffers,
}: {
    numberOfNewOffers: number
}) => (
    <Card
        backgroundColor="indigo20"
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        p={['space30', 'space40']}
    >
        <Box size={['64px', '80px']} mr={['space20', 'space30']}>
            <Image
                style={{ width: '100%', height: 'auto', minWidth: '64px' }}
                src={personReceivingOffers}
                alt="Person receiving new offers on their phone"
                priority={true}
            />
        </Box>

        <Box>
            <Box mb="space10">
                <Heading variant="60">
                    You’ve got{' '}
                    {numberOfNewOffers === 1
                        ? 'a new offer'
                        : `${numberOfNewOffers} new offers`}
                    !
                </Heading>
            </Box>

            <Text variant="100" color="light">
                You’re eligible for{' '}
                {numberOfNewOffers === 1
                    ? 'a new offer. Check it out now!'
                    : `${numberOfNewOffers} new offers. Check them out now!`}
            </Text>
        </Box>
    </Card>
)

export default ScoredTableNewOfferCard
