import Box from '@totallymoney/ui/components/Box'
import Text from '@totallymoney/ui/components/Text'
import theme from '@totallymoney/ui/theme'

import TooltipButton from '@components/TooltipButton'

interface TotallySureBannerOfferProps {
    description?: string
    id: string
    onTooltipPress: () => void
    title: string
}

const TotallySureBannerOffer = ({
    id,
    title,
    description,
    onTooltipPress,
}: TotallySureBannerOfferProps) => (
    <Box
        display="flex"
        flexWrap="wrap"
        justifyContent={description ? 'space-between' : 'flex-start'}
        backgroundColor="eligibilityLevel0-100"
        borderTopLeftRadius="borderRadius20"
        borderTopRightRadius="borderRadius20"
        borderBottomLeftRadius={0}
        borderBottomRightRadius={0}
        px="space20"
        pt="space10"
        pb="space30"
    >
        <Box
            display="flex"
            alignItems={['flex-start', 'flex-start', 'center']}
            flexDirection={['column', 'column', 'row']}
            py="space05"
        >
            <Box display="flex" alignItems="center" pr="space10">
                <Text
                    variant="120"
                    weight="bold"
                    color="white"
                    style={{ fontFamily: theme.buenosAires }}
                >
                    {title}
                </Text>
            </Box>

            {description ? (
                <Box mt="space025">
                    <Text variant="80" color="white">
                        {description}
                    </Text>
                </Box>
            ) : null}
        </Box>

        <Box alignSelf="center" display="flex" justifyContent="center">
            <TooltipButton
                size={24}
                color="white"
                title={`TotallySure tooltip for ${id}`}
                aria-label="What is TotallySure?"
                onPress={onTooltipPress}
            />
        </Box>
    </Box>
)

export default TotallySureBannerOffer
