import Box from '@totallymoney/ui/components/Box'
import Text from '@totallymoney/ui/components/Text'
import TotallySureBadge from '@totallymoney/ui/components/TotallySureBadge'

import { ProductDetail } from '@components/CreditCardContent/components/Content.interface'

import { shouldShowGuaranteedBadge } from '@utils/shouldShowGuaranteedBadge'

import {
    StyledCardTableItemProductDetail,
    StyledHeading,
} from './CardTableItemProductDetail.styled'

const CardTableItemProductDetail = ({
    title,
    value,
    detailType,
}: ProductDetail) => (
    <StyledCardTableItemProductDetail
        display="flex"
        flexDirection={['unset', 'column']}
        alignItems={['center', 'flex-start']}
        justifyContent="space-between"
    >
        <Box mb={[0, 'space10']}>
            {typeof title === 'string' ? (
                <Text variant="100" color="light">
                    {title}
                </Text>
            ) : (
                title
            )}
        </Box>

        <Box>
            {typeof value === 'string' ? (
                <StyledHeading variant="40" color="light">
                    {value}
                </StyledHeading>
            ) : (
                value
            )}

            {shouldShowGuaranteedBadge(detailType) ? (
                <Box
                    position={['relative', 'absolute']}
                    mt="space05"
                    display="flex"
                    justifyContent="flex-end"
                >
                    <TotallySureBadge text="Guaranteed" />
                </Box>
            ) : null}
        </Box>
    </StyledCardTableItemProductDetail>
)

export default CardTableItemProductDetail
