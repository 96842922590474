import styled from 'styled-components'

import Box from '@totallymoney/ui/components/Box'
import Heading from '@totallymoney/ui/components/Heading'
import theme from '@totallymoney/ui/theme'

export const StyledCardTableItemProductDetail = styled(Box)``

export const StyledHeading = styled(Heading)`
    padding-left: ${theme.space10};
    text-align: right;

    ${theme.mediaMediumPlus`
        padding-left: 0;
        text-align: left;
    `}
`
