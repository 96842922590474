import { GetServerSideProps } from 'next'
import Head from 'next/head'

import Box from '@totallymoney/ui/components/Box'
import LinkButton from '@totallymoney/ui/components/LinkButton'

import AnimatedLoadingPage from '@components/AnimatedLoadingPage'
import MetadataHead from '@components/MetadataHead'
import NotFoundPage from '@components/NotFoundPage'

import useAppQueryState from '@hooks/useAppQueryState'

import ScoredJourneyRoute from '@routes/ScoredJourneyRoute'
import ScoredTableSkeleton from '@routes/ScoredTableRoute/components/ScoredTableSkeleton'
import UnscoredTableRoute from '@routes/UnscoredTableRoute'

import { getCategoryMetadata } from '@utils/getCategoryMetadata'
import getIsScoredJourney from '@utils/getIsScoredJourney'
import { getIsValidCategoryPath } from '@utils/getIsValidCategoryPath'

interface CategoryPageProps {
    initialCategory: string
    isScoredJourney: boolean
}

const CategoryPage = ({
    isScoredJourney,
    initialCategory,
}: CategoryPageProps) => {
    const { isReady, appQueryState, getQueryStringWithAppState } =
        useAppQueryState()

    if (!getIsValidCategoryPath(initialCategory)) {
        return (
            <>
                <Head>
                    <title>Page not found</title>
                </Head>
                <NotFoundPage>
                    <Box pt="space30" maxWidth={['100%', '280px']}>
                        <LinkButton
                            variant="primary"
                            text="Back to cards"
                            href={`${
                                process.env.NEXT_PUBLIC_BASE_PATH
                            }?${getQueryStringWithAppState()}`}
                        />
                    </Box>
                </NotFoundPage>
            </>
        )
    }

    return (
        <>
            <MetadataHead
                metadata={
                    !isScoredJourney
                        ? getCategoryMetadata(appQueryState.categoryId!)
                        : undefined
                }
            />
            {isScoredJourney && !isReady ? (
                <>
                    <AnimatedLoadingPage fill={false} />
                    <ScoredTableSkeleton />
                </>
            ) : null}
            {isScoredJourney ? <ScoredJourneyRoute /> : <UnscoredTableRoute />}
        </>
    )
}

export const getServerSideProps: GetServerSideProps<
    CategoryPageProps
> = async ({ query, res }) => {
    const isScoredJourney = getIsScoredJourney(query)

    if (!isScoredJourney) {
        res.setHeader('Cache-Control', 'public, max-age=600')
    }

    return {
        props: {
            isScoredJourney,
            initialCategory: query.category as string,
        },
    }
}

export default CategoryPage
