import LinkInline from '@totallymoney/ui/components/LinkInline'
import Text from '@totallymoney/ui/components/Text'
import theme from '@totallymoney/ui/theme'

const BrokerInfo = () => (
    <Text data-testid="broker" variant="80" style={{ color: theme.grey100 }}>
        TotallyMoney is an independent credit broker, not a lender. We earn
        commission from partners, find out more{' '}
        <LinkInline
            target="_blank"
            href="https://help.totallymoney.com/en/articles/165239-how-do-you-make-money"
        >
            here
        </LinkInline>
        .
    </Text>
)

export default BrokerInfo
