import {
    ActiveCreditCard,
    CreditCardProductCategory,
    ScoredCreditCardProduct,
    ScoredCreditCardProductsCategory,
} from '@appTypes/EligibilityApi.interface'

import { getCardByCategory } from '@utils/getCardByCategory'
import { getSanitisedCardName } from '@utils/getSanitisedCardName'

interface Options {
    allActiveCards?: ActiveCreditCard[]
    category?: CreditCardProductCategory
    highlightCardPath?: string
    scoredCategories?: ScoredCreditCardProductsCategory[]
}

export enum HighlightCardStatus {
    CardEligible = 'CardEligible',
    CardIneligible = 'CardIneligible',
    NoCard = 'NoCard',
    NoData = 'NoData',
}

export type HighlightCardNoData = {
    card: null
    status: HighlightCardStatus.NoData
}

export type HighhlightCardNoCard = {
    card: null
    status: HighlightCardStatus.NoCard
}

export type HighlightCardEligible = {
    card: ScoredCreditCardProduct
    status: HighlightCardStatus.CardEligible
}

export type HighlightCardIneligible = {
    card: ActiveCreditCard
    status: HighlightCardStatus.CardIneligible
}

export type HighlightCard =
    | HighhlightCardNoCard
    | HighlightCardEligible
    | HighlightCardIneligible
    | HighlightCardNoData

function getHighlightCard(options: Options): HighlightCard {
    if (
        options.highlightCardPath == null ||
        options.category == null ||
        options.scoredCategories == null
    ) {
        return { status: HighlightCardStatus.NoData, card: null }
    }

    const scoredCard = getCardByCategory(options.scoredCategories, {
        cardId: options.highlightCardPath,
        category: options.category,
    })

    if (scoredCard != null) {
        return { status: HighlightCardStatus.CardEligible, card: scoredCard }
    }

    const ineligibleCard = options.allActiveCards?.find(
        product =>
            getSanitisedCardName(product.name) === options.highlightCardPath
    )

    if (ineligibleCard != null) {
        return {
            status: HighlightCardStatus.CardIneligible,
            card: ineligibleCard,
        }
    }

    return { status: HighlightCardStatus.NoCard, card: null }
}

export default getHighlightCard
