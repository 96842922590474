import Link from 'next/link'
import styled from 'styled-components'

import Text from '@totallymoney/ui/components/Text'

import { KBA_SCENARIO_KEYS, KbaScenario } from '@config/kbaScenarioKeys'

import { notEnoughInformationFaqUrl } from '@constants/helpLinks'

import { getAppConfig } from '@utils/getAppConfig'

const StyledText = styled(Text)`
    white-space: pre-line;
    a {
        text-decoration: underline;
    }
`

interface KbaStatusMessageProps {
    kbaScenario: KbaScenario
    onKbaScenarioPress: (kbaScenario: KbaScenario) => void
}

export const KbaStatusMessage = ({
    kbaScenario,
    onKbaScenarioPress,
}: KbaStatusMessageProps) => {
    const appConfig = getAppConfig()
    const accountUrl = appConfig.ACCOUNT_URL

    switch (kbaScenario) {
        case KBA_SCENARIO_KEYS.FOUND_BY_ALL_NEW_USER:
            return (
                <StyledText>
                    We also found your{' '}
                    <Link
                        href={`${accountUrl}dashboard`}
                        onClick={() => onKbaScenarioPress(kbaScenario)}
                    >
                        credit report
                    </Link>
                    .
                </StyledText>
            )

        case KBA_SCENARIO_KEYS.KBA_OK_HD_NOT_FOUND:
            return (
                <StyledText>
                    We found your{' '}
                    <Link
                        href={`${accountUrl}dashboard`}
                        onClick={() => onKbaScenarioPress(kbaScenario)}
                    >
                        credit report
                    </Link>
                    .
                </StyledText>
            )

        case KBA_SCENARIO_KEYS.NO_VNU_HD_FOUND:
            return (
                <StyledText>
                    We couldn&rsquo;t find your credit report right now.
                </StyledText>
            )

        case KBA_SCENARIO_KEYS.NO_VNU_HD_NOT_FOUND:
            return (
                <StyledText>
                    {`We couldn't find your credit report\n`}
                    <a
                        href={notEnoughInformationFaqUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => onKbaScenarioPress(kbaScenario)}
                    >
                        Find out more
                    </a>
                    .
                </StyledText>
            )

        case KBA_SCENARIO_KEYS.KBA_FAILED_HD_FOUND:
            return (
                <StyledText>
                    <Link
                        href={`${accountUrl}dashboard`}
                        onClick={() => onKbaScenarioPress(kbaScenario)}
                    >
                        Check your dashboard
                    </Link>{' '}
                    later to confirm your identity for your free credit report.
                </StyledText>
            )

        case KBA_SCENARIO_KEYS.KBA_FAILED_HD_NOT_FOUND:
            return (
                <StyledText>
                    <Link
                        href={`${accountUrl}dashboard`}
                        onClick={() => onKbaScenarioPress(kbaScenario)}
                    >
                        Check your dashboard
                    </Link>{' '}
                    to confirm your identity for your free credit report.
                </StyledText>
            )

        case KBA_SCENARIO_KEYS.THIN_FILE_HD_FOUND:
        case KBA_SCENARIO_KEYS.THIN_FILE_HD_NOT_FOUND:
            return (
                <StyledText>
                    There isn&apos;t enough info in your file to create a report{' '}
                    <Link
                        href={notEnoughInformationFaqUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => onKbaScenarioPress(kbaScenario)}
                    >
                        Find out why
                    </Link>
                    .
                </StyledText>
            )

        default:
            return <></>
    }
}
