import Box from '@totallymoney/ui/components/Box'
import Modal from '@totallymoney/ui/components/Modal'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'

import UnorderedList, { ListItem } from '@components/UnorderedList'

interface BestMatchModalProps {
    onModalClose: () => void
    showModal: boolean
}

const BestMatchModal = ({ showModal, onModalClose }: BestMatchModalProps) => {
    return (
        <Modal
            title="What is my Best Match?"
            hasHeader={false}
            hasTooltipHeader={true}
            isOpen={showModal}
            onClose={onModalClose}
            isFullScreen={true}
            hasMinHeight={false}
        >
            <Stack space="space20">
                <Text variant="100">
                    Your Best Match is the top result from our Match Factor
                    algorithm. Our Match Factor algorithm takes into account:
                </Text>
                <Box ml="space20">
                    <UnorderedList>
                        <ListItem>Your profile</ListItem>
                        <ListItem>Your eligibility</ListItem>
                        <ListItem>Features of the credit card</ListItem>
                        <ListItem>Lender criteria</ListItem>
                    </UnorderedList>
                </Box>
                <Text variant="100">
                    We are paid commission by partners, but this never
                    influences how products are ranked. We don&apos;t provide
                    financial advice. Remember to check all of your available
                    offers and choose what’s right for you.
                </Text>
            </Stack>
        </Modal>
    )
}

export default BestMatchModal
