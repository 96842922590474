import styled from 'styled-components'

import Box from '@totallymoney/ui/components/Box'
import theme from '@totallymoney/ui/theme'

import { StyledCardTableItemProductDetail } from '@components/CardTableItemProductDetail/CardTableItemProductDetail.styled'

import { borderStyle } from '@styles/borderStyle'

export const StyledCardTableContentBase = styled(Box)`
    gap: ${theme.space10};

    ${StyledCardTableItemProductDetail} + ${StyledCardTableItemProductDetail} {
        border-top: ${borderStyle};
    }

    ${theme.mediaMediumPlus`
        gap: ${theme.space20};

        ${StyledCardTableItemProductDetail} + ${StyledCardTableItemProductDetail} {
            padding-left: ${theme.space20};

            border-left: ${borderStyle};
            border-top: 0;
        }
    `}
`
