import Box from '@totallymoney/ui/components/Box'

const BannerStack = ({
    children,
}: {
    children: React.ReactNode
}): React.ReactElement => {
    return <Box mb="-space20">{children}</Box>
}

export default BannerStack
