import styled from 'styled-components'

import { focusOutlineStyle } from '@totallymoney/ui/styles/focus'
import theme from '@totallymoney/ui/theme'

export const MinimumCreditLimitUpdateWrapper = styled.button`
    border: 1px solid ${theme.grey60};
    border-radius: ${theme.space10};
    font-family: ${theme.buenosAires};
    font-size: 14px;
    padding: ${theme.space10};
    white-space: nowrap;
    overflow: hidden;
    padding-left: ${theme.space20};
    background-color: white;
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    > div > div {
        align-self: unset;
    }

    &:focus {
        ${focusOutlineStyle};
    }
`
