import Image from 'next/image'

import Box from '@totallymoney/ui/components/Box'

import TotallySureCornerLogo from '../../../../../../../../public/images/TotallySure-corner-logo.png'

const TotallySureCornerBanner = () => (
    <Box
        borderTopRightRadius="borderRadius20"
        position="absolute"
        top={0}
        right={0}
        zIndex={0}
        overflow="hidden"
    >
        <Box
            width={['86px', '94px', '110px']}
            height={['68px', '74px', '87px']}
        >
            <Image
                src={TotallySureCornerLogo}
                alt="TotallySure logo"
                fill={true}
            />
        </Box>
    </Box>
)

export default TotallySureCornerBanner
