import { ReactNode } from 'react'

import Box from '@totallymoney/ui/components/Box'

import { borderStyle } from '@styles/borderStyle'

export const ItemBase = ({
    children,
    productCode,
}: {
    children: ReactNode
    productCode: string
}) => (
    <Box
        backgroundColor="white"
        pt={['space20', 'space30']}
        px={['space30', 'space40']}
        borderRadius="borderRadius20"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.05)"
        data-testid={`unscored-table-item-${productCode}`}
    >
        {children}
    </Box>
)

export const ItemWrapper = ({ children }: { children: ReactNode }) => (
    <Box display="grid" gridAutoColumns="1fr" mb="space30">
        {children}
    </Box>
)

export const ItemHeading = ({ children }: { children: ReactNode }) => (
    <Box
        gridColumn={['span 12', '1/7', '1/10']}
        pb={['space20', 'space20', 'space30']}
    >
        {children}
    </Box>
)

export const ItemImage = ({ children }: { children: ReactNode }) => (
    <Box
        position="relative"
        display="flex"
        gridColumn={['1/13', '1/7', '1/4']}
        gridRow={['2', 'unset']}
        justifyContent={['center', 'flex-start']}
        pr="space10"
    >
        {children}
    </Box>
)

export const ItemContent = ({
    children,
    showBullets,
}: {
    children: ReactNode
    showBullets: boolean
}) => (
    <Box
        gridColumn={[
            'span 12',
            showBullets ? '1/6' : 'span 12',
            showBullets ? '4/7' : '4/10',
        ]}
        gridRow={['unset', '4', showBullets ? '2' : '2/4']}
        display={['block', 'flex']}
        justifyContent={['unset', 'center']}
        flexDirection="column"
        mt={['space20', 'space30', 0]}
        mb={['space20', 0]}
    >
        {children}
    </Box>
)

export const ItemButtons = ({ children }: { children: ReactNode }) => (
    <Box
        gridColumn={['span 12 / auto', '11/13']}
        gridRow={['unset', '2']}
        justifySelf={['unset', 'flex-end', 'center']}
        alignSelf={['unset', 'center']}
        width={['unset', '208px']}
    >
        {children}
    </Box>
)

export const ItemFooter = ({ children }: { children: ReactNode }) => (
    <Box
        gridColumn="span 12"
        pt="space10"
        pb="space20"
        px={[0, 'space10']}
        borderTop={borderStyle}
    >
        {children}
    </Box>
)
