import Card from '@totallymoney/ui/components/Card'

interface ScoredTableItemCardProps {
    children: React.ReactNode
    isMarketingOffer?: boolean
    isNewOfferOrBestMatch?: boolean
    productCode: string
}

const ScoredTableItemCard = ({
    children,
    isMarketingOffer,
    isNewOfferOrBestMatch,
    productCode,
}: ScoredTableItemCardProps) => (
    <Card
        p={['space20', 'space30']}
        data-testid={`scored-table-item-${productCode}`}
        pt={
            isMarketingOffer
                ? isNewOfferOrBestMatch
                    ? 'space30'
                    : 'space80'
                : 'space30'
        }
        position="relative"
    >
        {children}
    </Card>
)

export default ScoredTableItemCard
