import Box from '@totallymoney/ui/components/Box'

import { borderStyle } from '@styles/borderStyle'

const ScoredTableItemFooterWrapper = ({
    children,
}: {
    children: React.ReactNode
}) => (
    <Box gridColumn="1/13" pt="space10" borderTop={borderStyle}>
        {children}
    </Box>
)

export default ScoredTableItemFooterWrapper
