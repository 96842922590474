import styled from 'styled-components'

import { focusOutlineStyle } from '@totallymoney/ui/styles/focus'
import theme from '@totallymoney/ui/theme'

export const StyledButton = styled.button`
    padding: 0;
    border: 0;

    &:active,
    &:focus {
        ${focusOutlineStyle};
        border-radius: ${theme.radii.borderRadius05};
    }

    &:hover {
        cursor: pointer;
    }
`
