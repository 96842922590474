import Box from '@totallymoney/ui/components/Box'
import Card from '@totallymoney/ui/components/Card'
import LinkButton from '@totallymoney/ui/components/LinkButton'
import Stack from '@totallymoney/ui/components/Stack'

import { ScoredCreditCardProduct } from '@appTypes/EligibilityApi.interface'

import CardTableImage from '@components/CardTableImage'
import EligibilityIndicator from '@components/EligibilityIndicator'
import NextLinkButton from '@components/NextLinkButton'
import PreApprovedIndicator from '@components/PreApprovedIndicator'

import useAppQueryState from '@hooks/useAppQueryState'

import { getCardApr } from '@utils/getCardApr'
import { getIsProductPortraitImage } from '@utils/getIsProductPortraitImage'
import { getSanitisedCardName } from '@utils/getSanitisedCardName'

import { getEligibleContent } from './utils/getEligibleContent'

export interface HighlightHeroEligibleProps {
    applicationUrl: string
    item: ScoredCreditCardProduct
    onApplyPress: () => void
    onSeeMoreDetailsPress: () => void
    onShowPreApprovedModal: () => void
}

const HighlightHeroEligible = ({
    item,
    applicationUrl,
    onApplyPress,
    onSeeMoreDetailsPress,
    onShowPreApprovedModal,
}: HighlightHeroEligibleProps) => {
    const { getRouteWithAppState } = useAppQueryState()

    const content = getEligibleContent(item)

    return (
        <Card p="space30" px="space40">
            <Box display="grid" gridAutoColumns="1fr">
                <Box
                    position="relative"
                    gridColumn={['span 12', '1/6', '1/4']}
                    gridRow={['2', 'span 2']}
                    pr={[0, 'space10']}
                    display="flex"
                    alignItems={['flex-start', 'center']}
                    pb={['space20', 0]}
                >
                    <CardTableImage
                        cmsImageUrl={item.imageUrlMedium}
                        name={item.name}
                        isPortraitImage={getIsProductPortraitImage(
                            item.productCode
                        )}
                    />
                </Box>

                <Box
                    display="flex"
                    justifyContent={['center', 'flex-end']}
                    gridColumn={['span 12', '7/13', '10/13']}
                    gridRow={['3', '2', '1']}
                    justifySelf={['center', 'flex-end']}
                    pb={['space20', 'space20', 0]}
                    width="100%"
                >
                    <Box maxWidth="216px" width={['100%', 'unset']}>
                        {item.score > 0 && item.score < 10 ? (
                            <EligibilityIndicator score={item.score * 10} />
                        ) : null}

                        {item.isPreApproved ? (
                            <PreApprovedIndicator
                                id={item.productCode}
                                apr={getCardApr(item)}
                                onPress={onShowPreApprovedModal}
                            />
                        ) : null}
                    </Box>
                </Box>

                <Box
                    gridColumn={['span 12', 'span 12', '4/10']}
                    gridRow={['1', '1', 'span 2']}
                    display={['block', 'flex']}
                    justifyContent={['unset', 'flex-start', 'center']}
                    flexDirection="column"
                    mb={['space20', 'space20', 0]}
                    px={[0, 0, 'space30']}
                >
                    {content}
                </Box>

                <Box
                    gridColumn={['span 12', '10/13']}
                    gridRow={['4', '3', '2']}
                    justifySelf={['center', 'flex-end']}
                    alignSelf={['unset', 'flex-end']}
                    width={['240px', '208px']}
                    pt={[0, 0, 'space30']}
                >
                    <Stack space="space10" display="flex" alignItems="center">
                        <Box width="100%">
                            <LinkButton
                                text="Apply"
                                target="_blank"
                                isExternalLink
                                href={applicationUrl}
                                variant="primary"
                                onClick={evt => {
                                    evt.preventDefault()
                                    onApplyPress()
                                }}
                            />
                        </Box>

                        <NextLinkButton
                            text="See more details"
                            to={getRouteWithAppState([
                                'all-cards',
                                'card',
                                getSanitisedCardName(item.name),
                            ])}
                            variant="tertiaryTransparent"
                            shallow={true}
                            onClick={onSeeMoreDetailsPress}
                        />
                    </Stack>
                </Box>
            </Box>
        </Card>
    )
}

export default HighlightHeroEligible
