import { useMemo } from 'react'

import Box from '@totallymoney/ui/components/Box'
import Hero from '@totallymoney/ui/components/Hero'
import LinkButton from '@totallymoney/ui/components/LinkButton'
// @ts-ignore
import TrustBox from '@totallymoney/ui/components/TrustBox'

import { CreditCardProductCategory } from '@appTypes/EligibilityApi.interface'

import useLogEvents from '@hooks/useLogEvents'

import { getCategoryPathForId } from '@utils/getCategoryPathForId'

import creditCardsHeroImage from '../../../public/images/creditcards-hero.png'
import creditCardsHeroImage2x from '../../../public/images/creditcards-hero@2x.png'
import { getRedirectAccountUrl } from '../utils/getRedirectAccountUrl'

interface UnscoredPageHeaderProps {
    activeCategoryId: CreditCardProductCategory | null
}

function getCategoryTitle(categoryId: CreditCardProductCategory | null) {
    switch (categoryId) {
        case CreditCardProductCategory.BalanceTransfer:
            return 'Compare balance transfer cards'
        case CreditCardProductCategory.Cashback:
            return 'Compare cashback credit cards'
        case CreditCardProductCategory.Combined:
            return 'Compare balance transfer & purchase offer credit cards'
        case CreditCardProductCategory.PoorCredit:
            return 'Compare credit cards for poor credit'
        case CreditCardProductCategory.Purchase:
            return 'Compare purchase credit cards'
        case CreditCardProductCategory.Rewards:
            return 'Compare rewards credit cards'
        case CreditCardProductCategory.Travel:
            return 'Compare travel credit cards'
        default:
            return 'Find the right credit card for you'
    }
}

const UnscoredPageHeader = ({ activeCategoryId }: UnscoredPageHeaderProps) => {
    const logEvents = useLogEvents()

    const formUrl = useMemo(() => {
        const urlCategoryPath =
            activeCategoryId == CreditCardProductCategory.BestCards
                ? ''
                : getCategoryPathForId(activeCategoryId!)
        const data = urlCategoryPath
            ? { queryObject: { category: urlCategoryPath } }
            : {}
        return getRedirectAccountUrl(data)
    }, [activeCategoryId])

    const onCheckEligibilityClick = (
        event: React.SyntheticEvent<Element, Event>
    ) => {
        event.preventDefault()

        logEvents.logCheckEligibilityEvent({
            trigger: 'Herobox',
            category: activeCategoryId!,
        })

        window.location.href = formUrl
    }

    return (
        <>
            <Hero
                headline={getCategoryTitle(activeCategoryId)}
                checkList={[
                    'Get your free credit report',
                    'No harm to your credit rating',
                    'See your best offers',
                    'Free forever',
                ]}
                illustration={creditCardsHeroImage?.src}
                illustrationSrcset={`${creditCardsHeroImage?.src} 600w, ${creditCardsHeroImage2x?.src} 1024w`}
                illustrationSizes="(min-width: 960px) 720px, 100vw"
            >
                <Box mt="space15">
                    <LinkButton
                        variant="primaryDark"
                        data-gtm-event="eligibility-landing-header-button"
                        href={formUrl}
                        text="Check my eligibility"
                        onClick={onCheckEligibilityClick}
                    />
                </Box>
            </Hero>

            <Box pt={['space20', 0]} pb="space30" backgroundColor="white">
                <TrustBox widgetId="trustpilot-widget" />
            </Box>
        </>
    )
}

export default UnscoredPageHeader
