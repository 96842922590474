import Image from 'next/image'

import Box from '@totallymoney/ui/components/Box'
import Card from '@totallymoney/ui/components/Card'
import Heading from '@totallymoney/ui/components/Heading'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'

import { ActiveCreditCard } from '@appTypes/EligibilityApi.interface'

import phoneAlert from '../../../../../../../../public/images/info.png'

interface HighlightHeroIneligibleProps {
    item: ActiveCreditCard | null
    totalCreditCards: number
}

const HighlightHeroIneligible = ({
    item,
    totalCreditCards,
}: HighlightHeroIneligibleProps) => {
    const offerText = totalCreditCards === 1 ? 'offer' : 'offers'

    return (
        <Card
            py={['space30', 'space30', 'space40']}
            px={['space30', 'space30', 'space50']}
        >
            <Box display="flex">
                <Box mr={['space20', 'space30']}>
                    <Image
                        src={phoneAlert}
                        alt="phone with exclamation mark on screen"
                        width={64}
                    />
                </Box>

                <Stack space="space10" justifyContent="center">
                    <Heading variant="40">
                        Unfortunately you’re not eligible for the{' '}
                        {item?.name ?? 'card'}.
                    </Heading>

                    <Text variant="100" color="light">
                        {totalCreditCards > 0 ? (
                            <>
                                This could be for a number of reasons. However,
                                we have{' '}
                                <strong>
                                    {totalCreditCards} other card {offerText}
                                </strong>{' '}
                                below that you are eligible for.
                            </>
                        ) : (
                            <>This could be for a number of reasons.</>
                        )}
                    </Text>
                </Stack>
            </Box>
        </Card>
    )
}

export default HighlightHeroIneligible
