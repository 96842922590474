import { useCallback } from 'react'

import type {
    CreditCardProductCategory,
    ScoredCreditCardProduct,
} from '@appTypes/EligibilityApi.interface'
import type { TopThreeCardsEventRequest } from '@appTypes/TopThreeCards.interface'

import { LoggerKey } from '@config/logger'

import getAuthHeaders from '@queries/utils/getAuthHeaders'

import { getAppConfig } from '@utils/getAppConfig'
import getTopCreditCardsEventData from '@utils/getTopCreditCardsEventData'

import useAppQueryState from './useAppQueryState'
import { useBasin } from './useBasin'
import useFeatureFlags from './useFeatureFlags'
import useLogger from './useLogger'
import { getVariationsFromFeatureFlags } from './utils/getVariationsFromFeatureFlags'

const usePostCreditCardsEmailTrigger = () => {
    const appConfig = getAppConfig()
    const { appQueryState } = useAppQueryState()
    const { ids } = useBasin()
    const { featureFlags } = useFeatureFlags()
    const { logError } = useLogger()

    const postCreditCardsResultsEmailTrigger = useCallback(
        async (
            creditCards: ScoredCreditCardProduct[],
            category: CreditCardProductCategory
        ) => {
            const topCreditCards = getTopCreditCardsEventData(creditCards)

            if (topCreditCards == null) {
                return null
            }

            const url =
                typeof window !== 'undefined' ? window.location.href : ''

            const request: TopThreeCardsEventRequest = {
                url,
                category,
                scoreId: appQueryState.scoreId!,
                customerId: appQueryState.customerId!,
                partnername: appConfig.NAME_WHITELABEL,
                dailyId: ids?.dailyId!,
                sessionId: ids?.sessionId!,
                permanentId: ids?.permanentId!,
                client: 'web',
                variation: getVariationsFromFeatureFlags(featureFlags),
                ...topCreditCards,
            }

            if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
                console.log('[PostCreditCardsEmailTrigger:disabled] Send', {
                    creditCards,
                    category,
                    request,
                })
                return
            }

            try {
                await fetch(
                    `${process.env.NEXT_PUBLIC_BASE_PATH}/api/top-three-cards/`,
                    {
                        method: 'POST',
                        body: JSON.stringify(request),
                        headers: {
                            ...getAuthHeaders(ids),
                            'content-type': 'application/json',
                            'x-cc-dry-run':
                                process.env.NEXT_PUBLIC_APP_ENV === 'test'
                                    ? 'true'
                                    : 'false',
                        },
                    }
                )
            } catch (err) {
                logError(LoggerKey.PostCreditCardsEmailTriggerError, err)
            }
        },
        [appQueryState.customerId, appQueryState.scoreId, ids]
    )

    return postCreditCardsResultsEmailTrigger
}

export default usePostCreditCardsEmailTrigger
