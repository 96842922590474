import styled from 'styled-components'

import theme from '@totallymoney/ui/theme'

export const StyledSorterWrapper = styled.div`
    > span > select {
        border: 1px solid ${theme.grey60};
        border-radius: ${theme.space10};
        font-family: ${theme.buenosAires};
        font-size: 14px;
        padding: ${theme.space10};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 30px;
        padding-left: ${theme.space20};
    }
`
