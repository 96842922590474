import Box from '@totallymoney/ui/components/Box'

const ScoredTableItemHeaderWrapper = ({
    canShowEligibilityMeter,
    children,
}: {
    canShowEligibilityMeter: boolean
    children: React.ReactNode
}) => (
    <Box
        gridRow={1}
        gridColumn={['1/13', canShowEligibilityMeter ? '1/9' : '1/13', '1/11']}
    >
        {children}
    </Box>
)

export default ScoredTableItemHeaderWrapper
