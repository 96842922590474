import { StyledCardTableContentBase } from './CardTableContentBase.styled'

interface ContentBaseProps {
    children: React.ReactNode
    hasGuaranteedBadge?: boolean
}

const CardTableContentBase = ({
    hasGuaranteedBadge,
    children,
}: ContentBaseProps) => (
    <StyledCardTableContentBase
        display="flex"
        flexDirection={['column', 'row']}
        mb={[0, hasGuaranteedBadge ? 'space30' : 0]}
    >
        {children}
    </StyledCardTableContentBase>
)

export default CardTableContentBase
