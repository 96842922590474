import Box from '@totallymoney/ui/components/Box'
import Grid from '@totallymoney/ui/components/Grid'
import Heading from '@totallymoney/ui/components/Heading'
import Text from '@totallymoney/ui/components/Text'

const DownsellBanner = () => (
    <Box p="space30" backgroundColor="lightBlue20">
        <Grid>
            <Box gridColumn="1/13">
                <Box pb="space10">
                    <Heading variant="60" color="dark">
                        Other cards
                    </Heading>
                </Box>

                <Text variant="80">
                    Although you’re only eligible for a limited number of
                    balance transfer cards right now, you might be accepted for
                    these cards. You won’t be able to transfer an existing
                    balance to them, but they may be able to help you save money
                    in other ways.
                </Text>
            </Box>
        </Grid>
    </Box>
)

export default DownsellBanner
