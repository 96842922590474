import Skeleton from 'react-loading-skeleton'

import styled from 'styled-components'

import theme from '@totallymoney/ui/theme'

export const SkeletonBack = styled(Skeleton)`
    border-radius: ${theme.radii.borderRadius10};
    width: 125px;
    height: 19px;
`

export const SkeletonTitle = styled(Skeleton)`
    border-radius: ${theme.radii.borderRadius10};
    width: 300px;
    height: 47px;
`

export const SkeletonDescription = styled(Skeleton)`
    border-radius: ${theme.radii.borderRadius10};
    height: 26px;
    width: 200px;
`

export const SkeletonCategoryNavigation = styled(Skeleton)`
    display: inline-block;
    margin-right: ${theme.space10};
    border-radius: ${theme.radii.borderRadius10};
`

export const SkeletonSorter = styled(Skeleton)`
    height: 48px;
    width: 100%;
    border-radius: ${theme.radii.borderRadius10};
    ${theme.mediaMediumPlus`
        width: 350px;
    `}
`

export const SkeletonButtons = styled(Skeleton)`
    margin-bottom: ${theme.space10};
    height: 48px;
    border-radius: ${theme.radii.borderRadius30};
`

export const SkeletonContent = styled.div`
    > span {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: ${theme.space20};
        ${theme.mediaMediumPlus`
            grid-template-columns: repeat(4, 1fr);
        `}
    }
`

export const SkeletonContentItem = styled(Skeleton)`
    display: inline-block;
    border-radius: ${theme.radii.borderRadius10};
    ${theme.mediaMediumPlus`
        width: 100px;
        height: 100px;
    `}
`

export const SkeletonHeading = styled(Skeleton)`
    border-radius: ${theme.radii.borderRadius10};
    width: 100%;
    height: 29px;
    ${theme.mediaMediumPlus`
        width: 500px;
    `}
`
