import { ProductDetailType } from '@components/CreditCardContent/components/Content.interface'

export const shouldShowGuaranteedBadge = (
    detailType?: ProductDetailType
): boolean => {
    if (!detailType) {
        return false
    }

    return (
        detailType === ProductDetailType.GuaranteedExample ||
        detailType === ProductDetailType.GuaranteedCreditLimit
    )
}
