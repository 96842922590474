import Box from '@totallymoney/ui/components/Box'

const ScoredTableItemDetailsWrapper = ({
    canShowEligibilityMeter,
    children,
}: {
    canShowEligibilityMeter: boolean
    children: React.ReactNode
}) => (
    <Box
        gridColumn={['1/13', '1/13', '1/10']}
        gridRow={[3, canShowEligibilityMeter ? 2 : 3, 2]}
    >
        {children}
    </Box>
)

export default ScoredTableItemDetailsWrapper
