import * as yup from 'yup'

import { minimumCreditLimitRange } from '@config/minimumCreditLimitRange'

import formatNumberToCurrency from '@utils/formatNumberToCurrency'

const CREDIT_LIMIT_MESSAGE = `Enter credit limit between ${formatNumberToCurrency(
    minimumCreditLimitRange.min
)} - ${formatNumberToCurrency(minimumCreditLimitRange.max)}`

export const GuaranteedCreditLimitIntroFormSchema = yup.object().shape({
    minCreditLimitOption: yup
        .number()
        .nullable()
        .when('showCustomField', {
            is: (value: boolean) => value === false,
            then: schema =>
                schema
                    .typeError(CREDIT_LIMIT_MESSAGE)
                    .required(CREDIT_LIMIT_MESSAGE)
                    .integer(CREDIT_LIMIT_MESSAGE)
                    .min(minimumCreditLimitRange.min, CREDIT_LIMIT_MESSAGE)
                    .max(minimumCreditLimitRange.max, CREDIT_LIMIT_MESSAGE),
        }),

    minCreditLimitCustom: yup
        .number()
        .nullable()
        .when('showCustomField', {
            is: (value: boolean) => value === true,
            then: schema =>
                schema
                    .typeError(CREDIT_LIMIT_MESSAGE)
                    .required(CREDIT_LIMIT_MESSAGE)
                    .integer(CREDIT_LIMIT_MESSAGE)
                    .min(minimumCreditLimitRange.min, CREDIT_LIMIT_MESSAGE)
                    .max(minimumCreditLimitRange.max, CREDIT_LIMIT_MESSAGE),
        }),

    showCustomField: yup.bool().default(false),
})

export type GuaranteedCreditLimitIntroFormValues = yup.InferType<
    typeof GuaranteedCreditLimitIntroFormSchema
>
