import { Fragment, useEffect, useMemo, useRef } from 'react'

import { captureException } from '@sentry/nextjs'
import BrokerInfo from 'components/BrokerInfo'
import Cookies from 'js-cookie'

import Box from '@totallymoney/ui/components/Box'
import Grid from '@totallymoney/ui/components/Grid'
import Heading from '@totallymoney/ui/components/Heading'
import LinkButton from '@totallymoney/ui/components/LinkButton'
import LinkWithChevron from '@totallymoney/ui/components/LinkWithChevron'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'

import {
    ApplicationURLTriggerLocation,
    CreditCardProductCategory,
    GetMinimumGuaranteedCreditLimitResponse,
    GuaranteedOfferType,
    ScoredCreditCardProduct,
    ScoredCreditCardProductsCategory,
    ScoredExperiment,
} from '@appTypes/EligibilityApi.interface'

import AppAdoptionHeader from '@components/AppAdoptionHeader'
import AppAdoptionModal from '@components/AppAdoptionModal'
import BestMatchModal from '@components/BestMatchModal'
import CardTablePagination from '@components/CardTablePagination'
import CreditLimitModal from '@components/CreditLimitModal'
import ErrorBoundary from '@components/ErrorBoundary'
import GuaranteedOfferModal from '@components/GuaranteedOfferModal'
import MinimumCreditLimitModal from '@components/MinimumCreditLimitModal/MinimumCreditLimitModal'
import NextLinkButton from '@components/NextLinkButton'
import PreApprovedModal from '@components/PreApprovedModal'
import RepresentativeExampleModal from '@components/RepresentativeExampleModal'

import useAppQueryState from '@hooks/useAppQueryState'
import useFeatureFlag from '@hooks/useFeatureFlag'
import useLogEvents from '@hooks/useLogEvents'
import usePostCreditCardsEmailTrigger from '@hooks/usePostCreditCardsEmailTrigger'
import useSetState from '@hooks/useSetState'

import useGetCreditCardApplicationUrl from '@routes/ScoredTableRoute/hooks/useGetCardApplicationUrl'
import useGetDifferenceInNewCreditCardsOffers from '@routes/ScoredTableRoute/hooks/useGetDifferenceInNewCreditCardsOffers'
import {
    getIsSponsoredCreditCard,
    getSponsoredProducts,
} from '@routes/ScoredTableRoute/utils/getSponsoredProducts'

import { getAppConfig } from '@utils/getAppConfig'
import getCategoryByCategoryId from '@utils/getCategoryByCategoryId'
import { getCategoryPathForId } from '@utils/getCategoryPathForId'
import getGuaranteedOfferType from '@utils/getGuaranteedOfferType'
import { getSanitisedCardName } from '@utils/getSanitisedCardName'
import { getShowOfferDurationContent } from '@utils/getShowOfferDurationContent'
import { sortCardsBySortKey } from '@utils/sortCardsBySortKey'

import AdvertisementBanner from './components/AdvertisementBanner'
import DownsellBanner from './components/DownsellBanner'
import HighlightHero from './components/HighlightHero'
import ScoredTableHeader from './components/ScoredTableHeader'
import ScoredTableItem from './components/ScoredTableItem'
import ScoredTableNewOfferCard from './components/ScoredTableNewOfferCard'
import TotallySureTooltipBanner from './components/TotallySureTooltipBanner'
import useLogCheckMatchFactorRankingError from './hooks/useLogCheckMatchFactorRankingError'
import {
    CategoryComparatorKey,
    CategorySortKeyComparatorKeys,
    categorySortKeyComparatorKeys,
} from './utils/categorySortKeyComparatorKeys'
import { getCategorySortKeysToShow } from './utils/getCategorySortKeysToShow'
import {
    getDownsellCreditCards,
    getIsDownsellCreditCard,
} from './utils/getDownsellCreditCards'
import { HighlightCard, HighlightCardStatus } from './utils/getHighlightCard'
import { getIsBestMatchCard } from './utils/getIsBestMatchCard'
import { getValidCategorySortKey } from './utils/getValidCategorySortKey'

enum ModalType {
    AppAdoption = 'AppAdoption',
    BestMatch = 'BestMatch',
    CreditLimitModal = 'CreditLimitModal',
    GuaranteedOfferModal = 'GuaranteedOfferModal',
    MinimumCreditLimit = 'MinimumCreditLimit',
    PreApproved = 'PreApproved',
    RepresentativeExample = 'RepresentativeExample',
}

interface ScoredTableProps {
    activeCategoryId: CreditCardProductCategory
    hasEverUsedNativeApp?: boolean
    highlightCard: HighlightCard
    minCreditLimitData?: GetMinimumGuaranteedCreditLimitResponse
    scoredCategories: ScoredCreditCardProductsCategory[]
    scoredExperiments?: ScoredExperiment[]
    thirdPartyReference?: string
}

interface ScoredTableState {
    numberOfCardsToShow: number
    selectedCategory: CreditCardProductCategory
    selectedSortKey: keyof CategorySortKeyComparatorKeys[CreditCardProductCategory]
}

interface ModalState {
    activeCard: ScoredCreditCardProduct | null
    activeModal: ModalType | null
    creditCardApplicationUrl: string | null
    totallySureTooltipBanner: boolean | null
}

const NUMBER_OF_CARDS_TO_SHOW = 15

export const defaultModalState: ModalState = {
    activeCard: null,
    activeModal: null,
    creditCardApplicationUrl: null,
    totallySureTooltipBanner: null,
}

const ScoredTable = ({
    activeCategoryId,
    hasEverUsedNativeApp,
    highlightCard,
    minCreditLimitData,
    scoredCategories,
    thirdPartyReference,
}: ScoredTableProps) => {
    const appConfig = getAppConfig()
    const logEvents = useLogEvents()

    const { appQueryState, getRouteWithAppState, updateAppQueryState } =
        useAppQueryState()
    const getCardApplicationUrl = useGetCreditCardApplicationUrl()
    const postCreditCardsEmailTrigger = usePostCreditCardsEmailTrigger()
    const logCheckMatchFactorRankingError = useLogCheckMatchFactorRankingError()

    const isNewOfferBadgeExperimentEnabled =
        useFeatureFlag('experiment_showNewOfferBadge') === 'v1'

    const [modalState, updateModalState] =
        useSetState<ModalState>(defaultModalState)

    const [creditCardsState, setCreditCardsState] =
        useSetState<ScoredTableState>({
            selectedCategory: activeCategoryId,
            selectedSortKey: getValidCategorySortKey({
                categoryId: appQueryState.categoryId!,
                queryOption: appQueryState.sortBy as CategoryComparatorKey,
            }),
            numberOfCardsToShow:
                appQueryState.numberOfCardsToShow ?? NUMBER_OF_CARDS_TO_SHOW,
        })

    const newOfferProductCodes = useGetDifferenceInNewCreditCardsOffers(
        scoredCategories,
        creditCardsState.selectedCategory
    )

    const activeCategoryPath = getCategoryPathForId(
        creditCardsState.selectedCategory
    )

    const sponsoredCreditCards = useMemo(() => {
        return getSponsoredProducts(
            scoredCategories,
            creditCardsState.selectedCategory
        )
    }, [scoredCategories, creditCardsState.selectedCategory])

    const activeCategory = getCategoryByCategoryId(
        scoredCategories,
        creditCardsState.selectedCategory
    )

    const downsellCreditCards = useMemo(() => {
        return getDownsellCreditCards(
            scoredCategories,
            creditCardsState.selectedCategory
        )
    }, [scoredCategories, creditCardsState.selectedCategory])

    const activeCategoryCreditCards = useMemo(() => {
        if (activeCategory == null) {
            return null
        }
        const sortedCards = sortCardsBySortKey({
            cards: activeCategory.creditCards,
            category: creditCardsState.selectedCategory,
            sortKey: getValidCategorySortKey({
                categoryId: creditCardsState.selectedCategory,
                queryOption: creditCardsState.selectedSortKey,
            }),
            categorySortKeyComparatorKeys,
        })
        return [
            ...(sponsoredCreditCards ?? []),
            ...sortedCards,
            ...(downsellCreditCards ?? []),
        ]
    }, [
        creditCardsState.selectedSortKey,
        creditCardsState.selectedCategory,
        activeCategory,
        downsellCreditCards,
        sponsoredCreditCards,
    ])

    const numberOfTotallySureCardOffers = useMemo(
        () =>
            activeCategoryCreditCards?.filter(
                card =>
                    getGuaranteedOfferType({ creditCard: card }) ===
                    GuaranteedOfferType.MarketingOffer
            ).length,
        [activeCategoryCreditCards]
    )

    const canShowTotallySureTooltipBanner =
        !!numberOfTotallySureCardOffers && numberOfTotallySureCardOffers > 0

    useEffect(() => {
        logEvents.logScoredTableUpdateEvent({
            cards: activeCategoryCreditCards,
            minCreditLimit: minCreditLimitData?.minimumGuaranteedCreditLimit,
            category: creditCardsState.selectedCategory,
            sortedBy: creditCardsState.selectedSortKey,
            seenNewOffers: newOfferProductCodes.length > 0,
            numberOfNewOffersSeen: newOfferProductCodes.length,
        })

        if (sponsoredCreditCards?.length) {
            logEvents.logFeatureCardsViewEvent({
                category: creditCardsState.selectedCategory,
                numberOfFeaturedCards: sponsoredCreditCards.length,
            })
        }
    }, [activeCategoryCreditCards])

    const activeSortOptions = useMemo(() => {
        return getCategorySortKeysToShow(creditCardsState.selectedCategory)
    }, [creditCardsState.selectedCategory])

    const onCategoryChange = (category: CreditCardProductCategory) => {
        firstNewOfferItemRef.current = { element: null, productCode: null }
        logEvents.logCategoryChangeEvent({
            category,
        })

        setCreditCardsState({
            selectedCategory: category,
            selectedSortKey: getValidCategorySortKey({
                categoryId: category,
                queryOption: creditCardsState.selectedSortKey,
            }),
            numberOfCardsToShow: NUMBER_OF_CARDS_TO_SHOW,
        })
    }

    const onSortChange = (
        sortKey: keyof CategorySortKeyComparatorKeys[CreditCardProductCategory]
    ) => {
        logEvents.logSortChangeEvent({
            sortBy: sortKey,
            category: creditCardsState.selectedCategory,
        })

        setCreditCardsState({ selectedSortKey: sortKey })

        updateAppQueryState({
            sortby: sortKey,
        })
    }

    const resetModalState = () => {
        updateModalState(defaultModalState)
    }

    const onShowMoreResults = () => {
        const numberOfCardsToShow =
            creditCardsState.numberOfCardsToShow + NUMBER_OF_CARDS_TO_SHOW

        setCreditCardsState({
            numberOfCardsToShow: numberOfCardsToShow,
        })

        updateAppQueryState({
            show: `${numberOfCardsToShow}`,
        })
    }

    const onSeeMoreDetailsPress = ({
        card,
        cardPosition,
        trigger = ApplicationURLTriggerLocation.Row,
        isNewOffer = false,
    }: {
        card: ScoredCreditCardProduct
        cardPosition: number
        isNewOffer?: boolean
        trigger?: ApplicationURLTriggerLocation
    }) => {
        logEvents.logNavigateToCardPageEvent({
            productCode: card.productCode,
            rowNumber: cardPosition,
            trigger,
            isNewOffer,
        })
    }

    const onApplyPress = ({
        card,
        isBestMatchCard,
        cardPosition,
        applicationUrl,
        trigger = ApplicationURLTriggerLocation.Row,
        isNewOffer = false,
    }: {
        applicationUrl: string
        card: ScoredCreditCardProduct
        cardPosition: number
        isBestMatchCard: boolean
        isNewOffer?: boolean
        trigger?: ApplicationURLTriggerLocation
    }) => {
        logEvents.logCardApplyEvent({
            category: creditCardsState.selectedCategory,
            clickSource: appConfig.CLICK_SOURCE_WHITELABEL,
            isBestMatchCard: isBestMatchCard,
            isCounterOffer: card.guaranteedRate?.isCounterOffer ?? false,
            productCode: card.productCode,
            rowNumber: cardPosition,
            score: card.score,
            status: card.status,
            trigger,
            isNewOffer,
        })

        window.open(applicationUrl, '_blank')
    }

    const firstNewOfferItemRef = useRef<{
        element: HTMLLIElement | null
        productCode: string | null
    }>({
        element: null,
        productCode: null,
    })

    const renderItem = (card: ScoredCreditCardProduct, index: number) => {
        const isDownsellCard = getIsDownsellCreditCard(card)
        const isFirstDownsellCard = isDownsellCard && card.__isFirstDownsellCard
        const isSponsoredCard = getIsSponsoredCreditCard(card)
        const cardPosition = index + 1

        const isBestMatch = getIsBestMatchCard({
            currentCategory: creditCardsState.selectedCategory,
            currentSortKey: creditCardsState.selectedSortKey,
            hasHighlightCard:
                highlightCard?.status === HighlightCardStatus.CardEligible,
            index,
        })
        const showNewOfferBadge =
            isNewOfferBadgeExperimentEnabled &&
            newOfferProductCodes.includes(card.productCode)

        const applicationUrl = getCardApplicationUrl({
            category: isDownsellCard
                ? CreditCardProductCategory.PoorCredit
                : creditCardsState.selectedCategory,
            creditCard: card,
            creditCardIndex: cardPosition,
            isBestMatchCard: isBestMatch,
            thirdPartyReference: thirdPartyReference!,
            trigger: ApplicationURLTriggerLocation.Row,
            isNewOffer: showNewOfferBadge,
        })

        const sanitisedCardName = getSanitisedCardName(card.name)
        const setRef = (el: HTMLLIElement) => {
            if (
                el &&
                showNewOfferBadge &&
                firstNewOfferItemRef.current.element == null
            ) {
                firstNewOfferItemRef.current.element = el
                firstNewOfferItemRef.current.productCode = card.productCode
            }
        }

        return (
            <ErrorBoundary
                fallback={null}
                onError={({ error }) => captureException(error)}
            >
                <Box as="li" ref={setRef}>
                    {isFirstDownsellCard ? (
                        <Box mb="space30">
                            <DownsellBanner />
                        </Box>
                    ) : null}

                    <Box
                        backgroundColor={
                            isSponsoredCard ? 'lightBlue20' : 'none'
                        }
                        pb={isSponsoredCard ? 'space30' : 0}
                    >
                        {isSponsoredCard ? <AdvertisementBanner /> : null}

                        <Grid>
                            <Box gridColumn="1/13">
                                <ScoredTableItem
                                    item={card}
                                    category={creditCardsState.selectedCategory}
                                    isBestMatch={isBestMatch}
                                    isNewOffer={showNewOfferBadge}
                                    minCreditLimitData={minCreditLimitData}
                                    onShowBestMatchModal={() =>
                                        updateModalState({
                                            activeModal: ModalType.BestMatch,
                                        })
                                    }
                                    onShowPreApprovedModal={() =>
                                        updateModalState({
                                            activeModal: ModalType.PreApproved,
                                        })
                                    }
                                    onShowRepresentativeExampleModal={() =>
                                        updateModalState({
                                            activeModal:
                                                ModalType.RepresentativeExample,
                                        })
                                    }
                                    onShowGuaranteedOfferModal={() =>
                                        updateModalState({
                                            activeModal:
                                                ModalType.GuaranteedOfferModal,
                                            activeCard: card,
                                        })
                                    }
                                    onShowCreditLimitModal={() =>
                                        updateModalState({
                                            activeModal:
                                                ModalType.CreditLimitModal,
                                        })
                                    }
                                    onShowMinimumCreditLimitModal={() =>
                                        updateModalState({
                                            activeModal:
                                                ModalType.MinimumCreditLimit,
                                        })
                                    }
                                    renderButtons={() => {
                                        return (
                                            <Stack
                                                space="space10"
                                                direction={[
                                                    'column',
                                                    'row-reverse',
                                                    'column',
                                                ]}
                                                justifyContent="center"
                                                alignItems="center"
                                                data-testid="scoredCardButtons"
                                            >
                                                <Box
                                                    width={[
                                                        '100%',
                                                        '50%',
                                                        '100%',
                                                    ]}
                                                >
                                                    <LinkButton
                                                        text="Apply"
                                                        variant="primary"
                                                        href={applicationUrl}
                                                        rel="nofollow noopener"
                                                        target="_blank"
                                                        data-testid="applyButton"
                                                        isExternalLink={true}
                                                        data-gtm-event={
                                                            card.sponsored
                                                                ? 'applyNow-sponsored-row-postClick'
                                                                : 'applyNow-table-postClick'
                                                        }
                                                        onClick={event => {
                                                            event.preventDefault()
                                                            onApplyPress({
                                                                card,
                                                                cardPosition,
                                                                applicationUrl,
                                                                isBestMatchCard:
                                                                    isBestMatch,
                                                                isNewOffer:
                                                                    showNewOfferBadge,
                                                            })
                                                        }}
                                                    />
                                                </Box>

                                                <Box
                                                    width={[
                                                        '100%',
                                                        '50%',
                                                        '100%',
                                                    ]}
                                                    display="flex"
                                                    justifyContent="center"
                                                >
                                                    <NextLinkButton
                                                        to={getRouteWithAppState(
                                                            [
                                                                isDownsellCard
                                                                    ? 'credit-cards-bad-credit'
                                                                    : activeCategoryPath,
                                                                'card',
                                                                sanitisedCardName,
                                                            ]
                                                        )}
                                                        shallow={true}
                                                        text="See more details"
                                                        variant="tertiaryTransparent"
                                                        onClick={() =>
                                                            onSeeMoreDetailsPress(
                                                                {
                                                                    card,
                                                                    cardPosition,
                                                                    isNewOffer:
                                                                        showNewOfferBadge,
                                                                }
                                                            )
                                                        }
                                                    />
                                                </Box>
                                            </Stack>
                                        )
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Box>
                </Box>
            </ErrorBoundary>
        )
    }

    const shouldShowAppPromotionHeader =
        typeof window !== 'undefined' &&
        window.innerWidth <= 425 &&
        appQueryState.showAppPromotion === 'true' &&
        hasEverUsedNativeApp

    const shouldShowAppPromotionModal =
        shouldShowAppPromotionHeader &&
        Cookies.get('appPromotionPreferWebToAppJourney') !== 'true'

    useEffect(() => {
        if (shouldShowAppPromotionModal) {
            updateModalState({
                activeModal: ModalType.AppAdoption,
            })
            logEvents.logAppPromotionView({ promotion: 'creditCardsTable' })
        }
    }, [shouldShowAppPromotionModal])

    useEffect(() => {
        if (activeCategoryCreditCards == null) {
            return
        }
        postCreditCardsEmailTrigger(
            activeCategoryCreditCards.filter(
                card => !getIsSponsoredCreditCard(card)
            ),
            creditCardsState.selectedCategory
        )
    }, [
        activeCategoryCreditCards,
        creditCardsState.selectedCategory,
        postCreditCardsEmailTrigger,
    ])

    useEffect(() => {
        if (activeCategoryCreditCards == null) {
            return
        }

        if (creditCardsState.selectedSortKey === 'matchFactor') {
            logCheckMatchFactorRankingError(activeCategoryCreditCards)
        }
    }, [
        logCheckMatchFactorRankingError,
        activeCategoryCreditCards,
        creditCardsState.selectedCategory,
        creditCardsState.selectedSortKey,
    ])

    useEffect(() => {
        setCreditCardsState({ selectedCategory: activeCategoryId })
    }, [activeCategoryId])

    const totalCreditCards = scoredCategories[0].totalCreditCards
    const isHighlightCardEligible =
        highlightCard.status === HighlightCardStatus.CardEligible

    const highlightCardApplicationUrl = isHighlightCardEligible
        ? getCardApplicationUrl({
              category: creditCardsState.selectedCategory,
              creditCard: highlightCard.card,
              creditCardIndex: 0,
              isBestMatchCard: false,
              thirdPartyReference: thirdPartyReference!,
              trigger: ApplicationURLTriggerLocation.Header,
              isNewOffer: false,
          })
        : null

    const modalGuaranteedOfferType = getGuaranteedOfferType({
        creditCard: modalState.activeCard,
    })

    const handleNewOffersBannerClick = () => {
        if (firstNewOfferItemRef.current.element != null) {
            firstNewOfferItemRef.current?.element.scrollIntoView({
                behavior: 'smooth',
            })
            logEvents.logClickOnNewOffersBanner({
                productCode: firstNewOfferItemRef.current.productCode!,
            })
        }
    }

    return (
        <>
            {shouldShowAppPromotionHeader ? (
                <AppAdoptionHeader customerId={appQueryState.customerId} />
            ) : null}

            <Box backgroundColor="productNeutral60">
                <Grid>
                    <Box mt="space30" mb="space10" gridColumn="1/13">
                        <LinkWithChevron
                            href={`${appConfig.ACCOUNT_URL}offers?customerId=${appQueryState.customerId}`}
                            variant="100"
                            direction="left"
                        >
                            Back to offers
                        </LinkWithChevron>
                    </Box>
                </Grid>
                <Grid>
                    <Box gridColumn="1/13" pb="space30">
                        <Box mb="space10">
                            <Heading variant="100">Your card results</Heading>
                        </Box>

                        <Box>
                            <Text variant="120">
                                {totalCreditCards}{' '}
                                {totalCreditCards === 1 ? 'offer' : 'offers'}{' '}
                                across all categories
                            </Text>
                        </Box>
                    </Box>

                    <Box gridColumn="1/13" pb="space30">
                        <BrokerInfo />
                    </Box>

                    {newOfferProductCodes.length > 0 ? (
                        <Box
                            gridColumn={['1/13', '1/13', '1/9']}
                            mb="space30"
                            style={{
                                cursor: firstNewOfferItemRef.current
                                    ? 'pointer'
                                    : 'default',
                            }}
                            role={
                                firstNewOfferItemRef.current
                                    ? 'button'
                                    : 'banner'
                            }
                            onClick={handleNewOffersBannerClick}
                        >
                            <ScoredTableNewOfferCard
                                numberOfNewOffers={newOfferProductCodes.length}
                            />
                        </Box>
                    ) : null}
                </Grid>

                {highlightCard.status !== HighlightCardStatus.NoData ? (
                    <Grid>
                        <Box
                            gridColumn="1/13"
                            mb="space30"
                            data-testid="highlight-hero"
                        >
                            <HighlightHero
                                applicationUrl={highlightCardApplicationUrl}
                                scoredCategories={scoredCategories}
                                highlightedCreditCard={highlightCard}
                                onApplyButtonPress={() => {
                                    if (isHighlightCardEligible) {
                                        onApplyPress({
                                            applicationUrl:
                                                highlightCardApplicationUrl!,
                                            card: highlightCard.card,
                                            isBestMatchCard: false,
                                            cardPosition: 0,
                                            trigger:
                                                ApplicationURLTriggerLocation.Header,
                                        })
                                    }
                                }}
                                onSeeMoreDetailsPress={() => {
                                    if (isHighlightCardEligible) {
                                        onSeeMoreDetailsPress({
                                            card: highlightCard.card,
                                            cardPosition: 0,
                                            trigger:
                                                ApplicationURLTriggerLocation.Header,
                                        })
                                    }
                                }}
                                onTooltipPress={() => {
                                    updateModalState({
                                        activeModal: ModalType.PreApproved,
                                    })
                                }}
                                onShowGuaranteedOfferModal={() =>
                                    updateModalState({
                                        activeModal:
                                            ModalType.GuaranteedOfferModal,
                                        activeCard:
                                            highlightCard.card as ScoredCreditCardProduct,
                                    })
                                }
                            />
                        </Box>
                    </Grid>
                ) : null}

                <Box mb="space30">
                    <ScoredTableHeader
                        categories={scoredCategories}
                        activeCategory={activeCategoryId}
                        onCategoryChange={onCategoryChange}
                        sortValue={creditCardsState.selectedSortKey}
                        onSortChange={sortKey =>
                            onSortChange(
                                sortKey as keyof CategorySortKeyComparatorKeys[CreditCardProductCategory]
                            )
                        }
                        sortOptions={activeSortOptions}
                        onShowMinimumCreditLimitModal={() =>
                            updateModalState({
                                activeModal: ModalType.MinimumCreditLimit,
                            })
                        }
                    />
                </Box>

                {canShowTotallySureTooltipBanner ? (
                    <Grid>
                        <Box mb="space30" gridColumn="1/13">
                            <TotallySureTooltipBanner
                                numberOfTotallySureCardOffers={
                                    numberOfTotallySureCardOffers
                                }
                                onClick={() =>
                                    updateModalState({
                                        activeModal:
                                            ModalType.GuaranteedOfferModal,
                                        totallySureTooltipBanner: true,
                                    })
                                }
                            />
                        </Box>
                    </Grid>
                ) : null}

                <Box as="ul" display="grid" gridGap="space30" mb="space30">
                    {activeCategoryCreditCards
                        ?.slice(0, creditCardsState.numberOfCardsToShow)
                        .map((card, index) => {
                            return (
                                <Fragment
                                    key={`${card.productCode}_${
                                        getIsSponsoredCreditCard(card)
                                            ? 'sponsored'
                                            : ''
                                    }`}
                                >
                                    {renderItem(card, index)}
                                </Fragment>
                            )
                        })}
                </Box>
                <Grid>
                    {activeCategory != null ? (
                        <Box gridColumn="1/13" mb="space20">
                            <CardTablePagination
                                totalCreditCards={
                                    activeCategory.totalCreditCards
                                }
                                numberOfCardsToShow={
                                    creditCardsState.numberOfCardsToShow!
                                }
                                showMoreResultsButton={
                                    creditCardsState.numberOfCardsToShow <
                                    activeCategory.totalCreditCards
                                }
                                onShowMoreResults={onShowMoreResults}
                            />
                        </Box>
                    ) : null}

                    <Box gridColumn="1/13" mb="space20">
                        <BrokerInfo />
                    </Box>
                </Grid>
            </Box>

            <PreApprovedModal
                showModal={modalState.activeModal == ModalType.PreApproved}
                onModalClose={resetModalState}
            />
            <RepresentativeExampleModal
                showModal={
                    modalState.activeModal == ModalType.RepresentativeExample
                }
                onModalClose={resetModalState}
            />
            <BestMatchModal
                showModal={modalState.activeModal == ModalType.BestMatch}
                onModalClose={resetModalState}
            />
            <AppAdoptionModal
                showModal={modalState.activeModal == ModalType.AppAdoption}
                onModalClose={resetModalState}
                customerId={appQueryState.customerId}
            />
            <MinimumCreditLimitModal
                showModal={
                    modalState.activeModal == ModalType.MinimumCreditLimit
                }
                onModalClose={resetModalState}
            />
            <CreditLimitModal
                showModal={
                    modalState.activeModal === ModalType.CreditLimitModal
                }
                onModalClose={resetModalState}
            />
            <GuaranteedOfferModal
                showModal={
                    modalState.activeModal === ModalType.GuaranteedOfferModal
                }
                onModalClose={resetModalState}
                offerType={
                    modalState.totallySureTooltipBanner
                        ? GuaranteedOfferType.MarketingOffer
                        : modalGuaranteedOfferType
                }
                showOfferDurationContent={
                    modalState.totallySureTooltipBanner
                        ? true
                        : getShowOfferDurationContent({
                              category: creditCardsState.selectedCategory,
                              card: modalState.activeCard,
                          })
                }
            />
        </>
    )
}

export default ScoredTable
