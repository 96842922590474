import { captureException } from '@sentry/nextjs'

import ErrorBoundary from '@components/ErrorBoundary'
import { DynamicFrequentlyAskedQuestions } from '@components/FrequentlyAskedQuestions'
import TermDescriptions from '@components/TermDescriptions'

import useAppQueryState from '@hooks/useAppQueryState'

import { useUnscoredCategoriesQuery } from '@queries/useUnscoredCategoriesQuery'

import UnscoredPageHeader from './components/UnscoredPageHeader'
import UnscoredTable from './components/UnscoredTable'
import UnscoredTableError from './components/UnscoredTableError'
import UnscoredTableSkeleton from './components/UnscoredTableSkeleton'

const UnscoredTableRoute = () => {
    const { appQueryState } = useAppQueryState()

    const unscoredCategoriesResponse = useUnscoredCategoriesQuery({
        select: data => data,
    })

    return (
        <>
            <UnscoredPageHeader activeCategoryId={appQueryState.categoryId!} />
            <ErrorBoundary
                fallback={<UnscoredTableError />}
                onError={({ error }) => captureException(error)}
            >
                <>
                    {unscoredCategoriesResponse.isLoading ? (
                        <UnscoredTableSkeleton />
                    ) : null}
                    {unscoredCategoriesResponse.isSuccess ? (
                        <UnscoredTable
                            unscoredCategories={
                                unscoredCategoriesResponse.data!
                            }
                            activeCategoryId={appQueryState.categoryId!}
                        />
                    ) : null}
                    {unscoredCategoriesResponse.isError ? (
                        <UnscoredTableError />
                    ) : null}
                    <TermDescriptions
                        showMatchFactorDescription={false}
                        showCreditCardTypesDescription={true}
                        showPreApprovedDescription={true}
                        showExclusiveDescription={true}
                        showVariableAprDescription={false}
                        showPrePopulation={true}
                        showSeeParticularCardDescription={true}
                    />
                    {unscoredCategoriesResponse.isSuccess ? (
                        <DynamicFrequentlyAskedQuestions
                            category={appQueryState.categoryId!}
                        />
                    ) : null}
                </>
            </ErrorBoundary>
        </>
    )
}

export default UnscoredTableRoute
