import { ReactNode } from 'react'

import Box from '@totallymoney/ui/components/Box'
import Card from '@totallymoney/ui/components/Card'

import { borderStyle } from '@styles/borderStyle'

export const ItemBase = ({
    children,
    productCode,
}: {
    children: ReactNode
    productCode: string
}) => (
    <Card
        p={['space20', 'space30']}
        data-testid={`scored-table-item-${productCode}`}
    >
        {children}
    </Card>
)

export const ItemWrapper = ({ children }: { children: ReactNode }) => (
    <Box display="grid" gridAutoColumns="1fr" mb="space30">
        {children}
    </Box>
)

export const ItemHeading = ({ children }: { children: ReactNode }) => (
    <Box
        gridColumn={['span 12', '1/7', '1/10']}
        pb={['space20', 'space20', 'space10']}
    >
        {children}
    </Box>
)

export const ItemImage = ({ children }: { children: ReactNode }) => (
    <Box
        position="relative"
        gridColumn={['span 12', '1/7', '1/4']}
        gridRow={['2', 'unset']}
        pr={[0, 'space20']}
        pb={['space20', 0]}
        maxWidth={['180px', 'unset']}
        justifySelf={['center', 'unset']}
    >
        {children}
    </Box>
)

export const ItemIndicatorContent = ({ children }: { children: ReactNode }) => (
    <Box
        gridColumn={['span 12', '7/13', '11/13']}
        gridRow={['3', '1']}
        maxWidth={['unset', '216px']}
        justifySelf={['unset', 'flex-end', 'center']}
        pb={[0, 'space20', 0]}
        display="flex"
        justifyContent={['center', 'unset']}
    >
        {children}
    </Box>
)

export const ItemContent = ({ children }: { children: ReactNode }) => (
    <Box
        gridColumn={['span 12', 'span 12', '4/11']}
        gridRow={['unset', '4', '2/4']}
        display={['block', 'flex']}
        justifyContent={['unset', 'center']}
        flexDirection="column"
        mt={['space20', 'space20', 0]}
        mb={['space20', 0]}
        pr={[0, 'space50']}
    >
        {children}
    </Box>
)

export const ItemButtons = ({ children }: { children: ReactNode }) => (
    <Box
        gridColumn={['span 12 / auto', '11/13']}
        gridRow={['unset', '2']}
        justifySelf={['unset', 'flex-end', 'center']}
        alignSelf={['unset', 'flex-end']}
        width={['unset', '208px']}
        pt={[0, 0, 'space30']}
    >
        {children}
    </Box>
)

export const ItemGuaranteedOfferQuestion = ({
    children,
}: {
    children: ReactNode
}) => (
    <Box
        gridColumn={['span 12 / auto', 'span 12 / auto']}
        gridRow={['unset', '7', '5']}
        mt={[0, 'space40']}
        mb={['space20', 0]}
    >
        {children}
    </Box>
)

export const ItemFooter = ({ children }: { children: ReactNode }) => (
    <Box
        gridColumn="span 12"
        pt="space10"
        pb="space20"
        px={[0, 'space10']}
        borderTop={borderStyle}
    >
        {children}
    </Box>
)
