import styled from 'styled-components'

import { focusStyles } from '@totallymoney/ui/styles/focus'
import theme from '@totallymoney/ui/theme'

export const StyledButton = styled.button`
    background-color: transparent;
    border-width: 0;
    padding: 0;
    width: 100%;

    ${focusStyles}

    &:active, &:focus {
        border-radius: ${theme.radii.borderRadius20};
    }

    &:hover {
        cursor: pointer;
    }
`
