import Skeleton from 'react-loading-skeleton'

import styled from 'styled-components'

import Box from '@totallymoney/ui/components/Box'
import Grid from '@totallymoney/ui/components/Grid'
import theme from '@totallymoney/ui/theme'

import * as Layout from '../UnscoredTable/components/UnscoredTableItem/components/UnscoredTableItemLayout'

const SkeletonCategoryNavigation = styled(Skeleton)`
    display: inline-block;
    margin-right: ${theme.space10};
    border-radius: ${theme.radii.borderRadius10};
`

const SkeletonSorter = styled(Skeleton)`
    height: 48px;
    width: 100%;
    border-radius: ${theme.radii.borderRadius10};

    ${theme.mediaMediumPlus`
        width: 350px;
    `}
`

const SkeletonButtons = styled(Skeleton)`
    margin-bottom: ${theme.space10};
    height: 48px;
    border-radius: ${theme.radii.borderRadius30};
`

const SkeletonContent = styled.div`
    > span {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: ${theme.space20};

        ${theme.mediaMediumPlus`
            grid-template-columns: repeat(4, 1fr);
        `}
    }
`

const SkeletonContentItem = styled(Skeleton)`
    display: inline-block;
    border-radius: ${theme.radii.borderRadius10};

    ${theme.mediaMediumPlus`
        width: 100px;
        height: 100px;
    `}
`

const SkeletonHeading = styled(Skeleton)`
    border-radius: ${theme.radii.borderRadius10};
    width: 100%;
    height: 29px;

    ${theme.mediaMediumPlus`
        width: 500px;
    `}
`

const SkeletonUnscoredTableItem = () => (
    <Layout.ItemBase productCode="skeleton">
        <Layout.ItemWrapper>
            <Layout.ItemHeading>
                <SkeletonHeading />
            </Layout.ItemHeading>

            <Layout.ItemImage>
                <Skeleton
                    height={132}
                    width={208}
                    borderRadius={theme.radii.borderRadius10}
                />
            </Layout.ItemImage>

            <Layout.ItemContent showBullets={false}>
                <SkeletonContent>
                    <SkeletonContentItem count={4} inline={true} />
                </SkeletonContent>
            </Layout.ItemContent>

            <Layout.ItemButtons>
                <SkeletonButtons count={2} />
            </Layout.ItemButtons>
        </Layout.ItemWrapper>

        <Layout.ItemFooter>
            <Skeleton count={2} borderRadius={theme.radii.borderRadius10} />
        </Layout.ItemFooter>
    </Layout.ItemBase>
)

const UnscoredTableSkeleton = () => {
    return (
        <Box backgroundColor="productNeutral60" py="space20">
            <Box mb="space50">
                <Grid>
                    <Box
                        gridColumn="1/13"
                        overflowY="hidden"
                        overflowX="scroll"
                        height={38}
                        width="100%"
                        mb="space30"
                    >
                        <SkeletonCategoryNavigation
                            count={8}
                            inline={true}
                            height={38}
                            width={120}
                        />
                    </Box>
                    <Box
                        gridColumn="1/13"
                        flex={1}
                        style={{ textAlign: 'right' }}
                    >
                        <SkeletonSorter />
                    </Box>
                </Grid>
            </Box>
            <Grid>
                <Box display="grid" gridColumn="1/13" gridGap="18px">
                    <SkeletonUnscoredTableItem />
                    <SkeletonUnscoredTableItem />
                    <SkeletonUnscoredTableItem />
                </Box>
            </Grid>
        </Box>
    )
}

export default UnscoredTableSkeleton
