import Image from 'next/image'

import Box from '@totallymoney/ui/components/Box'
import Heading from '@totallymoney/ui/components/Heading'
import Text from '@totallymoney/ui/components/Text'

import womanWithMagnifyingGlass from '../../../../../../public/images/woman-with-magnify-glass.png'

const UnscoredTableNoResults = () => {
    return (
        <Box gridColumn="1/13" display="flex" justifyContent="center">
            <Box display="flex" justifyContent="center" pr="space30">
                <Image
                    src={womanWithMagnifyingGlass}
                    alt="Woman holding a magnifying glass"
                    width="100"
                    height="100"
                    priority={true}
                />
            </Box>
            <Box display="flex" justifyContent="center" flexDirection="column">
                <Box mb="space05">
                    <Heading variant="60">
                        Sorry, we couldn&apos;t find any cards for this category
                    </Heading>
                </Box>
                <Text variant="100" color="light">
                    Please select another category
                </Text>
            </Box>
        </Box>
    )
}

export default UnscoredTableNoResults
