import { useCallback } from 'react'

import { ScoredCreditCardProduct } from '@appTypes/EligibilityApi.interface'

import { LoggerKey } from '@config/logger'

import useAppQueryState from '@hooks/useAppQueryState'
import useLogger from '@hooks/useLogger'

const useLogCheckMatchFactorRankingError = () => {
    const { logError } = useLogger()
    const { appQueryState } = useAppQueryState()

    const logMatchFactorRankingError = useCallback(
        (creditCards: ScoredCreditCardProduct[] | null) => {
            if (!Array.isArray(creditCards) || creditCards.length === 0) {
                return
            }

            const firstCard = creditCards[0]
            const lastCard = creditCards[creditCards.length - 1]

            if (
                creditCards.length > 1 &&
                firstCard != null &&
                lastCard != null &&
                lastCard.score > firstCard.score
            ) {
                logError(LoggerKey.MatchFactorRankingError, {
                    firstCardScore: firstCard.score,
                    lastCardScore: lastCard.score,
                    customerId: appQueryState.customerId,
                })
            }
        },
        []
    )

    return logMatchFactorRankingError
}

export default useLogCheckMatchFactorRankingError
